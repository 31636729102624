export class Toast {
  static async load() {
    return import("react-hot-toast").then((toastModule) => toastModule.default);
  }

  static success(message: string) {
    this.load().then((toast) => toast.success(message));
  }

  static error(message: string) {
    this.load().then((toast) => toast.error(message));
  }
}
