/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchCMSForSSR, useCMS, UseCMSOptions } from "./useCMS";

export type UseCMSBlog = {
  _id: string;
  title?: string;
  metaTitle?: string;
  slug?: string;
  coverImage?: string;
  description?: string;
  metaDescription?: string;
  date?: string;
  updatedAt?: string;
  createdAt?: string;
  author?: string;
  content?: any;
  plainTags?: string[];
  digitalDropTags?: string[];
  licensorTags?: string[];
  scenicProjectionTags?: string[];
  showVariantTags?: string[];
  showBrandTags?: string[];
  choreoGuideTags?: string[];
  solutionTags?: string[];
  projectorTags?: string[];
  writerTags?: string[];
  displayGetStartedForm?: boolean;
};

interface UseCMSBlogFilter {
  plainTags?: string[];
  digitalDropTags?: string[];
  licensorTags?: string[] | string;
  scenicProjectionTags?: string[];
  showVariantTags?: string[];
  showBrandTags?: string[];
  choreoGuideTags?: string[];
  solutionTags?: string[];
  projectorTags?: string[];
  storyTypeTags?: string[];
  _id?: string[];
  slug?: string[];
  isFeatured?: boolean;
}

interface UseCMSBlogExists {
  plainTags?: true;
  digitalDropTags?: true;
  licensorTags?: true;
  scenicProjectionTags?: true;
  showVariantTags?: true;
  showBrandTags?: true;
  choreoGuideTags?: true;
  solutionTags?: true;
  projectorTags?: true;
  storyTypeTags?: true;
}

export interface UseCMSBlogOptions extends UseCMSOptions {
  filter?: UseCMSBlogFilter;
  exists?: UseCMSBlogExists;
}

/**
 * @comment
 * Blogs are always sorted in a descending fashion based on `date` parameter.
 */
const DEFAULT_BLOG_SORT = {
  date: -1,
};

export const requestBlogsSSR = async (options?: UseCMSBlogOptions) => {
  return await fetchCMSForSSR({
    ...options,
    sort: DEFAULT_BLOG_SORT,
    endpoint: "blog/filter",
  });
};

export const useCMSBlogs = (options?: UseCMSBlogOptions, ready?: boolean) => {
  const { data, loading, page, pages } = useCMS<UseCMSBlog>({
    ...options,
    sort: DEFAULT_BLOG_SORT,
    endpoint: `blog/filter`,
    ready,
  });

  return {
    blogs: data,
    loading,
    page,
    pages,
  };
};
