import { useCMS, UseCMSOptions, UseCMSResult } from "./useCMS";

export type UseCMSWorkshop = {
  _id: string;
  title?: string;
  description?: string;
  image?: string;
  buttonText?: string;
  buttonURL?: string;
};

interface UseCMSWorkshopResult<T> extends UseCMSResult<T> {
  workshops: T[];
}

export const useCMSWorkshops = (
  options?: UseCMSOptions
): UseCMSWorkshopResult<UseCMSWorkshop> => {
  const { data, loading, page, pages } = useCMS<UseCMSWorkshop>({
    ...options,
    endpoint: "workshop/filter",
  });

  return {
    workshops: data || [],
    loading,
    page,
    pages,
  };
};
