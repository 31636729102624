import { Formatter, useBusiness, Validators } from "@with-nx/hooks-n-helpers";
import { DesignedInput, DesignedSelect } from "@with-nx/simple-ui/atoms";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";

import { CalendlyDetails, ContactDetails } from "./types";

interface ContactFormProps {
  initial?: ContactDetails;
  change?: (details: ContactDetails, valid: boolean) => void;
  value?: CalendlyDetails;
  action?: "get-started" | string;
  referrer?: string;
  mobile?: boolean;
}

export const ContactForm = (props: ContactFormProps) => {
  const [values, _values] = useState<ContactDetails>(props?.initial || {});
  const [valid, _valid] = useState(true);
  const [focus, _focus] = useState(true);
  const business = useBusiness();

  useEffect(() => {
    let _valid = valid;

    if (values.name?.length === 0 || values.name === undefined) {
      _valid = false;
    }

    if (
      values.organization?.length === 0 ||
      values.organization === undefined
    ) {
      _valid = false;
    }

    if (
      values.organization_type?.length === 0 ||
      values.organization_type === undefined
    ) {
      _valid = false;
    }

    if (values.email?.length === 0 || values.email === undefined) {
      _valid = false;
    }

    if (
      values.phone_number?.length === 0 ||
      values.phone_number === undefined
    ) {
      _valid = false;
    }

    props.change?.(values, _valid);
  }, [values, valid]);

  useEffect(() => {
    if (props.action === "get-started" && typeof props.referrer === "string") {
      if (props.referrer === "/scenic-projections") {
        _values((values) => ({
          ...values,
          message:
            "I just came from the Scenic Projections Landing page and I have some questions...",
        }));
      }
      if (props.referrer === "/choreography-guides") {
        _values((values) => ({
          ...values,
          message:
            "I just came from the Choreography Guides Landing page and I have some questions...",
        }));
      }
      if (props.referrer.includes("/shows/")) {
        const show: string | undefined = props.referrer.split("/")[3];
        if (show) {
          const friendly = show
            ?.split("-")
            ?.map((i) => {
              if (i === "kids") {
                return "KIDS";
              } else if (i === "jr") {
                return "JR.";
              }
              return Formatter.friendly(i);
            })
            .join(" ");

          _values((values) => ({
            ...values,
            message: `I just came from the ${friendly} landing page and I have some questions...`,
            show: friendly,
          }));
        }
      }
    }
  }, [props.action, props.referrer]);

  return (
    <Row gutter={[36, 18]}>
      <Col xs={24}>
        <DesignedInput
          label="Name"
          value={values.name}
          change={(name) => _values({ ...values, name })}
          bottom={12}
          convert="capitalize"
        />
      </Col>
      <Col xs={props.mobile ? 24 : 12}>
        <DesignedInput
          label="Organization Name"
          value={values.organization}
          change={(organization) => _values({ ...values, organization })}
          bottom={12}
          convert="capitalize"
        />
      </Col>
      <Col xs={props.mobile ? 24 : 12}>
        <DesignedSelect
          icon="down"
          label="Organization Type"
          value={values.organization_type}
          change={(organization_type) =>
            _values({ ...values, organization_type })
          }
          empty
          bottom={12}
          options={business.types.map((i) => [i.name, i.name])}
        />
      </Col>
      <Col xs={props.mobile ? 24 : 12}>
        <DesignedInput
          label="Email"
          value={values.email}
          change={(email) => _values({ ...values, email })}
          bottom={12}
          focus={() => _focus(true)}
          blur={() => {
            _valid(Validators.isValidEmailAddress(values.email || ""));
            _focus(false);
          }}
          convert="lowercase"
          error={
            focus
              ? undefined
              : valid
              ? undefined
              : "Please enter a valid email address."
          }
        />
      </Col>
      <Col xs={props.mobile ? 24 : 12}>
        <DesignedInput
          label="Phone Number"
          value={values.phone_number}
          change={(phone_number) => _values({ ...values, phone_number })}
          bottom={12}
        />
      </Col>
      <Col xs={props.mobile ? 24 : 12} sm={props.mobile ? 24 : 16}>
        <DesignedInput
          label="Show"
          value={values.show}
          change={(show) => _values({ ...values, show })}
          bottom={12}
        />
      </Col>
      <Col xs={props.mobile ? 24 : 12} sm={props.mobile ? 24 : 8}>
        <DesignedInput
          label="Opening Night"
          value={values.opening_night}
          change={(opening_night) => _values({ ...values, opening_night })}
          bottom={12}
          type="date"
        />
      </Col>
      <Col xs={24}>
        <DesignedInput
          label="Message"
          value={values.message}
          change={(message) => _values({ ...values, message })}
          bottom={12}
          textarea={true}
        />
      </Col>
    </Row>
  );
};

export default ContactForm;
